import React from "react";
import {
    LoginByHash,
    ForgotPassword,
    Registration,
    ResetPassword,
} from "../modules/Auth/Pages";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./helpers/PrivateRoute";
import User from "../modules/User/components";
import Dashboard from "../modules/Dashboard/components/index.tsx";
import Promo from "../modules/Promo/components/index";
import AllComponents from "../modules/Common/AllComponents";
import Trackers from "../modules/Trackers/pages/Trackers";
import Payments from "../modules/Payments/pages/Payments";
import Affiliates from "../modules/Affiliates/pages/Affiliates";
import Costs from "../modules/Costs/components";
import UsersAdmin from "../modules/Users/pages/UsersAdmin";
import { LoginById } from "../modules/Auth/Pages/LoginById";
import Login from "../modules/Auth/Pages/Login";
import { UsersProfile } from "../modules/Users/pages/UsersProfile";
import { APP_ROUTES } from "./constants/constantsUrl";
import UsersCreate from "../modules/Users/pages/UsersCreate";
import {
    AffiliateBrandItemEdit,
    AffiliateBrands,
    AffiliateLanding,
    AffiliateLandingCreate,
    AffiliateProgramEdit,
    AffiliateProgramCreate,
    ChatCreateForm,
    ChatInnerPage,
    ChatsListAdmin,
    ChatsListUser,
    ChatCreateFormAdmin,
    ChatInnerPageAdmin,
    GamersFTDReport,
    GamersReport,
    AffiliatesReport,
    ManageableNotificationsList,
    NotificationEdit,
    NotificationCreate,
    ManageableList,
    ManageableItemEdit,
    ManageableListAddUser,
    ManageableItemCreate,
    TranslationList,
    TranslationKeyCreate,
    TranslationEdit,
    TranslationImportExcel,
    AffiliatesGeoReport,
    AffiliateDifferenceReport,
    GeoReport,
    FrodersList,
    FroderEdit,
    FroderBulk,
} from "../pages/inner";
import { permissions } from "./constants/roles";
import Page404 from "../pages/error/pages/Page404";
import { Home, Offers, Terms } from "../pages/outer";
import Contacts from "../modules/Contacts/components/Contacts";
import GamersSearchPage from "../pages/inner/Manage/GamersSearchPage/GamersSearchPage";
import { Technical } from "../modules/Dashboard/pages/Technical";

export default (
    <Switch>
        <Route path={APP_ROUTES.login.main} component={Login} exact />
        <Route path={APP_ROUTES.login.hash} component={LoginByHash} />
        <Route
            path={APP_ROUTES.user.restore_password}
            component={ForgotPassword}
            exact
        />
        <Route
            path={APP_ROUTES.user.reset_password}
            component={ResetPassword}
            exact
        />
        <Route path={APP_ROUTES.registration} component={Registration} exact />
        <Route path={APP_ROUTES.sign_up} component={Registration} exact />
        <Route path={APP_ROUTES.contacts} component={Contacts} exact />
        <Route path="/offers" component={Offers} exact />
        <Route path="/terms" component={Terms} exact />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/technical-dashboard" component={Technical} />
        <PrivateRoute path="/promo" component={Promo} />
        <PrivateRoute path="/trackers" component={Trackers} />
        <PrivateRoute path="/payments" component={Payments} />
        <PrivateRoute path="/affiliates" component={Affiliates} />
        <PrivateRoute
            path={APP_ROUTES.costs.main}
            component={Costs}
            permissions={permissions.dashboard.header.costs}
        />
        <PrivateRoute path="/user" component={User} />
        <PrivateRoute path="/all-components" component={AllComponents} />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.users}
            component={UsersAdmin}
            permissions={permissions.manage.users}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.gamers}
            component={GamersSearchPage}
            permissions={permissions.manage.users}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.managers}
            component={ManageableList}
            permissions={permissions.manage.managers}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.manageable_list_edit}
            component={ManageableItemEdit}
            permissions={permissions.manage.managers}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.manageable_list_create}
            component={ManageableItemCreate}
            permissions={permissions.manage.managers}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.manageable_list_add_user}
            component={ManageableListAddUser}
            permissions={permissions.manage.managers}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.affiliates_report}
            component={AffiliatesReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.affiliates_geo_report}
            component={AffiliatesGeoReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.affiliates_difference_report}
            component={AffiliateDifferenceReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.gamers_ftd_report}
            component={GamersFTDReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.gamers_report}
            component={GamersReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            path={APP_ROUTES.reports.geo_report}
            component={GeoReport}
            permissions={permissions.reports}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.users_create}
            component={UsersCreate}
            permissions={permissions.manage.users}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.user_profile}
            component={UsersProfile}
            permissions={permissions.manage.users}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.login.id}
            component={LoginById}
            permissions={permissions.manage.users}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.notifications.list}
            component={ManageableNotificationsList}
            permissions={permissions.manage.notifications}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.notifications.create}
            component={NotificationCreate}
            permissions={permissions.manage.notifications}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.manage.notifications.edit}
            component={NotificationEdit}
            permissions={permissions.manage.notifications}
        />
        <PrivateRoute
            path={APP_ROUTES.content.translations.list}
            component={TranslationList}
            permissions={permissions.content.translation}
        />
        <PrivateRoute
            path={APP_ROUTES.content.translations.translation}
            component={TranslationEdit}
            permissions={permissions.content.translation}
        />
        <PrivateRoute
            path={APP_ROUTES.content.translations.create_key}
            component={TranslationKeyCreate}
            permissions={permissions.content.translation}
        />
        <PrivateRoute
            path={APP_ROUTES.content.translations.import_excel}
            component={TranslationImportExcel}
            permissions={permissions.content.translation}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.brands}
            exact
            component={AffiliateBrands}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.brand_edit}
            exact
            component={AffiliateBrandItemEdit}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.landing_edit}
            exact
            component={AffiliateLanding}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.landing_create}
            exact
            component={AffiliateLandingCreate}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.program_edit}
            exact
            component={AffiliateProgramEdit}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.affiliates_programs.program_create}
            exact
            component={AffiliateProgramCreate}
            permissions={permissions.manage.brands}
        />
        <PrivateRoute
            path={APP_ROUTES.chat.inner}
            exact
            component={ChatInnerPage}
        />
        <PrivateRoute
            path={APP_ROUTES.chat.create}
            exact
            component={ChatCreateForm}
        />
        <PrivateRoute path={APP_ROUTES.chat.list} exact component={ChatsListUser} />
        <PrivateRoute
            path={APP_ROUTES.chat.admin.inner}
            exact
            component={ChatInnerPageAdmin}
            permissions={permissions.chat.admin}
        />

        <PrivateRoute
            path={APP_ROUTES.chat.admin.list}
            exact
            component={ChatsListAdmin}
            permissions={permissions.chat.admin}
        />
        <PrivateRoute
            path={APP_ROUTES.chat.admin.create}
            exact
            component={ChatCreateFormAdmin}
            permissions={permissions.chat.admin}
        />
        <PrivateRoute
            path={APP_ROUTES.manage.fraudsters.list}
            exact
            component={FrodersList}
            permissions={permissions.manage.fraudsters}
        />
        <PrivateRoute
            path={APP_ROUTES.manage.fraudsters.edit}
            exact
            component={FroderEdit}
            permissions={permissions.manage.fraudsters}
        />
        <PrivateRoute
            path={APP_ROUTES.manage.fraudsters.create}
            exact
            component={FroderBulk}
            permissions={permissions.manage.fraudsters}
        />
        <Route path="/" component={Home} />
        <Route path="*" component={Page404} />
    </Switch>
);
